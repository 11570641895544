
import Errors from 'Base/classes/Errors';
import Dispatch from 'Base/classes/Dispatch';
import Auth from 'Base/classes/Auth';
import Quote from 'Base/classes/Quote';

export default (id, countryCode) => ({
    id: id,
    phoneMenu : false,
    countryCode,
    user: {
        email: null,
        first_name: null,
        last_name: null,
        phone: countryCode === 'be' ? '+32' : '+33',
        password: null,
        g_recaptcha_response: null
    },
    loading: false,
    errors: new Errors(),
    init()
    {
        this.user.email = this.$parent.user.email;
    },
    isValid()
    {
        return this.user.email && this.user.first_name && this.user.last_name && this.user.phone && this.user.password;
    },
    back()
    {
        new Dispatch(this, 'quote-previous-step');
    },
    save()
    {
        this.loading = true;
        this.errors.clear();
        
        let alpine = this;
        grecaptcha.ready(function() {
            grecaptcha.execute(window.google_recaptcha_key, {action: 'submit'}).then(function(token) {
                alpine.user.g_recaptcha_response = token;
                alpine.$nextTick(() => alpine.register());
            });
        });
    },
    register()
    {
        new Auth().register(this.user)
            .then((response) => {
                new Quote(this.id).user(response.user)
                    .then(() => {
                        new Dispatch(this, 'quote-next-step', {step: 'register', user: response.user})
                    });
            })
            .catch((response) => {
                this.errors.record(response.data.errors);
                this.loading = false;
            });
    },
    getInputBorderColor(name)
    {
        return this.errors.has(name) ? 'border-color: #EF4444' : '';
    },

    replaceCountryCode(prefix) {
        if(!this.user.phone) this.user.phone = "";
        if(this.user.phone.startsWith("+32") || this.user.phone.startsWith("+33")) {
            this.user.phone = this.user.phone.replace(/^\+32|^\+33/, prefix);
        }
        else {
            this.user.phone = prefix + this.user.phone;
        }

        this.phoneMenu = false;
         
    },

    formatPhoneNumber()
    {
        this.phoneMenu = false;
        if(this.user.phone.startsWith("0", 3)) {
            this.user.phone = this.user.phone.slice(0,3) + this.user.phone.slice(4)
        }
    }
});